import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player/lazy";
import Buy from "./Buy";
export const formatPrice = (price) => {
  return Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "XOF",
  })
    .format(price)
    .replace("XOF", "C FCFA");
};

export const getPrice = ({ setPrice, setLoading }) => {
  setLoading(true);
  axios
    .get(`${process.env.REACT_APP_SERVER}/api/v3/book/price`)
    .then((res) => {
      if (res.data.success) {
        setPrice(res.data.price);
      }
    })
    .catch((err) => {
      setPrice(5000);
    })
    .finally(() => {
      setLoading(false);
    });
};
const Info = () => {
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPrice({
      setPrice,
      setLoading,
    });
  }, []);
  return (
    <div className="relative flex flex-col items-center justify-center w-full">
      <div className="container grid items-center justify-center grid-cols-1 gap-4 py-6 md:grid-cols-2 lg:grid-cols-3">
        <div className="mx-auto">
          <div className="mb-4 text-center text-white">
            Que contient ce livre ?
          </div>

          <div className="min-w-[300px] min-h-[200px] bg-white p-2 flex-1 flex items-center">
            <ReactPlayer
              url="https://fb.watch/la_DPMzLqw/"
              width="100%"
              height={"100%"}
              light={
                <img
                  src={require("../assets/images/Videobanner.png")}
                  alt="slide"
                  className="h-[200px]"
                  loading="lazy"
                />
              }
              controls
            />
          </div>
        </div>
        <div className="mx-auto ">
          <div className="mb-4 text-center text-white">Disponible</div>
          <div className="w-[300px] h-[200px] bg-white p-2">
            <div className="flex flex-col items-center justify-start h-full">
              <h3 className="text-black font-bold text-[16px] text-center mb-3 flex-1 flex items-center">
                La version physique du livre est disponible dans les pays
                suivants :
              </h3>{" "}
              <p className="text-[var(--primary-color)] font-bold text-[18px] text-center flex-1 flex items-center">
                CÔTE D’IVOIRE - SÉNÉGAL
              </p>
            </div>
          </div>
        </div>
        <div className="mx-auto">
          <div className="mb-4 text-center text-white">support du livre</div>
          <div className="w-[300px] h-[200px] bg-white ">
            <div className="flex flex-col items-center justify-start h-full ">
              <div className="flex-1 w-full p-2 shadow-lg">
                <p className="text-[var(--primary-color)] font-bold text-[22px] text-center">
                  {formatPrice(10000)}
                </p>
                <p className="text-black font-bold text-[20px] text-center mb-3">
                  Support physique
                </p>
              </div>{" "}
              <div className="flex-1 w-full p-2">
                <p className="text-[var(--primary-color)] font-bold text-[22px] text-center">
                  {loading ? "..." : formatPrice(price)}
                </p>
                <p className="text-black font-bold text-[20px] text-center mb-3">
                  Support Numérique
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-wrap justify-center my-4">
        <Buy />
      </div>
    </div>
  );
};

export default Info;
