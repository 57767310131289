import React, { useEffect } from "react";

import Router from "./navigation";
import { SubmittedProvider } from "./components/SubmitContext";
import { getNotification } from "./lib/Socket";
import { Toaster } from "react-hot-toast";

function App() {
  useEffect(() => {
    getNotification();
  }, []);

  return (
    <SubmittedProvider>
      <Router />
      <Toaster />
    </SubmittedProvider>
  );
}

export default App;
