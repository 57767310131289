import React, { useState } from "react";
import { useSubmitted } from "./SubmitContext";
import { toast } from "react-hot-toast";
import axios from "axios";

const SubmitAlert = ({ open }) => {
  const { data, setSubmitted, setData } = useSubmitted();

  const messageWithLink = data?.message?.replace(
    /(https?:\/\/)?bit\.ly\/wave225/,
    ""
  );
  const [pending, setPending] = useState(false);

  const handlePaiement = async () => {
    setPending(true);
    axios
      .post(`${process.env.REACT_APP_SERVER}/api/v3/commandes/initiate`, data)
      .then((res) => {
        if (res.data.success) {
          window.location.href = res.data.url;
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        toast.error("Une erreur s'est produite");
      })
      .finally(() => {
        setPending(false);
      });
  };

  return (
    <div
      className={`${
        open ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-75 flex justify-center items-center z-[60]`}
    >
      <section className="rounded bg-white relative max-h-[100%]  max-w-lg flex flex-col items-center justify-center overflow-y-scroll">
        <div className="p-4">
          <div className="flex items-center justify-center h-full flex-col">
            <svg
              fill="currentColor"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              className="text-green-800 w-12 h-12 mb-2"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <g>
                  {" "}
                  <path d="M418.275,418.275c95.7-95.7,95.7-250.8,0-346.5s-250.8-95.7-346.5,0s-95.7,250.8,0,346.5S322.675,513.975,418.275,418.275 z M157.175,207.575l55.1,55.1l120.7-120.6l42.7,42.7l-120.6,120.6l-42.8,42.7l-42.7-42.7l-55.1-55.1L157.175,207.575z"></path>{" "}
                </g>{" "}
              </g>
            </svg>
            <p className="text-green-700 text-2xl mb-4">
              Vous avez déjà commandé
            </p>
            <p
              className="text-gray-700 text-sm mb-4 text-center"
              dangerouslySetInnerHTML={{ __html: "M./Mme " + messageWithLink }}
            />

            {data?.deliveryCity === "Hors d'Abidjan" && (
              <table className="border-collapse border my-4 p-4 md:w-[400px] w-[90%]">
                <tbody>
                  <tr>
                    <td className="border p-4">
                      <div>
                        <p className="font-bold mb-4">Payez sans frais :</p>
                        <button
                          className="text-sm w-full h-full border-none text-white rounded text-md font-bold border px-6 py-2 bg-[#1D4ED8] no-underline transition-colors"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => handlePaiement()}
                        >
                          {pending
                            ? "Initialisation en cours..."
                            : "Payer en ligne"}
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}

            <table className="border-collapse border  my-4 p-4 md:w-[400px] w-[90%]">
              <tbody>
                <tr>
                  <td className="border p-2 text-gray-700 text-sm">A </td>
                  <td className="border p-2">
                    <span className=" text-sm font-semibold">
                      {data?.deliveryLocation}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="border p-2 text-gray-700 text-sm">
                    Votre numéro de téléphone
                  </td>
                  <td className="border p-2">
                    <span className="text-sm font-semibold">
                      {data?.phoneNumber}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="border p-2 text-gray-700 text-sm">Montant</td>
                  <td className="border p-2">
                    <span className="text-sm font-semibold">
                      {data?.price} FCFA
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <button
              className="px-4 py-2 bg-red-400 rounded-lg text-white my-4"
              onClick={() => {
                setSubmitted(false);
                setData({});
              }}
            >
              Fermer
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SubmitAlert;
