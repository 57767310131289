import { useState, useEffect } from "react";

const useCurrentDate = () => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000); // Update every minute

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return currentDate;
};

export default useCurrentDate;
