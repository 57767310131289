import React from "react";
import Avis from "../components/Avis";
import Comment from "../components/Comment";
import Contact from "../components/Contact";
import Desc from "../components/Desc";
import External from "../components/External";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Info from "../components/Info";
import Nav from "../components/Nav";
import SubmitAlert from "../components/SubmitAlert";
import { useSubmitted } from "../components/SubmitContext";

function Home() {
  const { submitted } = useSubmitted();

  return (
    <div>
      <SubmitAlert open={submitted} />
      <Nav />
      <Hero />
      <Desc />
      <Info />
      <Avis />
      <Comment />
      <Contact />
      <Faq />
      <External />
      <Footer />
    </div>
  );
}

export default Home;
