import React from "react";
import { useSubmitted } from "../components/SubmitContext";
import FormModal from "../components/FormModal";
import SubmitAlert from "../components/SubmitAlert";
import { useNavigate } from "react-router-dom";

const Commande = () => {
  const { submitted } = useSubmitted();
  const navigate = useNavigate();
  if (submitted) return <SubmitAlert open={submitted} />;
  return (
    <div>
      <FormModal closeModal={() => navigate("/")} />;
    </div>
  );
};

export default Commande;
