import { toast } from "react-hot-toast";
import { io } from "socket.io-client";
import { africanNames } from "../data";

let socket = io(`${process.env.REACT_APP_SERVER}`, {
  transports: ["websocket", "polling", "flashsocket"],
});

const getNotification = () => {
  socket.on("getNotification", (data) => {
    toast.success(`${data.msg} - à l'instant`, {
      duration: 5000,
      icon: "",
      position: "bottom-left",
      style: {
        background: "#333",
        color: "#fff",
        fontSize: "14px",
        borderRadius: "8px",
      },
    });
  });
};

const handleNotification = ({ msg }) => {
  socket.emit("sendNotification", {
    msg,
  });
};
let notificationCount = 0;

const sendIntervalNotification = () => {
  const randomIndex = Math.floor(Math.random() * africanNames.length);
  const randomName = africanNames[randomIndex];
  const message = `${randomName.firstName} ${randomName.lastName} (${randomName.city}) vient de passer commande`;

  notificationCount++;

  toast.success(`${message} - à l'instant`, {
    key: notificationCount,
    duration: 5000,
    icon: "",
    position: "bottom-left",
    style: {
      background: "#333",
      color: "#fff",
      fontSize: "14px",
      borderRadius: "8px",
    },
  });
};

setInterval(sendIntervalNotification, 15000);

export { getNotification, handleNotification };
