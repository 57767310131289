import React from "react";

const Nav = () => {
  return (
    <div className="fixed top-0 z-50 flex items-center justify-center w-full h-16 px-4 bg-white md:justify-start">
      <ul className="flex items-center gap-6 text-sm">
        <a href="/">
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Accueil
          </li>
        </a>
        <a href="#faq">
          {" "}
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            FAQ
          </li>
        </a>
        <a href="#avis">
          {" "}
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Témoignage
          </li>
        </a>
        <a href="#contact">
          {" "}
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Contact
          </li>
        </a>
        <a href="#autre">
          <li className="text-gray-600 cursor-pointer hover:text-gray-800 hover:underline">
            Autre livre
          </li>
        </a>
      </ul>
    </div>
  );
};

export default Nav;
