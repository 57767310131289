import React from "react";
import useCurrentDate from "./useCurrentDate";

const DateSelector = ({ selected, onChange }) => {
  const currentDate = useCurrentDate();
  const currentTime = currentDate.getHours() * 100 + currentDate.getMinutes();

  const isPast930 = currentTime >= 930;

  const handleSelectChange = (e) => {
    const selectedDate = new Date(e.target.value);

    if (
      selectedDate < currentDate ||
      (selectedDate.getDate() === currentDate.getDate() && isPast930) ||
      e.target.value === "null"
    ) {
      onChange(null);
    } else {
      onChange(selectedDate);
    }
  };

  const isSunday = (date) => date.getDay() === 0;

  const renderOptions = () => {
    const options = [];
    const today = new Date(currentDate);

    for (let i = 0; options.length < 3; i++) {
      today.setDate(currentDate.getDate() + i);

      if (!isSunday(today) && !(i === 0 && isPast930)) {
        options.push(
          <option key={today.toISOString()} value={today.toISOString()}>
            {today.toLocaleDateString("fr-FR", { weekday: "long" })}
          </option>
        );
      }
    }

    return options;
  };

  return (
    <select
      value={selected && selected.toISOString()}
      onChange={handleSelectChange}
      className="border border-gray-300 px-2 py-3 w-full rounded text-gray-700"
    >
      <option value="null">Selectionnez</option>
      {renderOptions()}
    </select>
  );
};

export default DateSelector;
