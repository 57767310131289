import React, { createContext, useState, useContext } from "react";

const SubmittedContext = createContext();

export function SubmittedProvider({ children }) {
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({});
  return (
    <SubmittedContext.Provider
      value={{ submitted, setSubmitted, data, setData }}
    >
      {children}
    </SubmittedContext.Provider>
  );
}

export function useSubmitted() {
  return useContext(SubmittedContext);
}
