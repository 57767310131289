import React, { useState } from "react";
import CustomCarousel from "./CustomCarousel";
import ReactPlayer from "react-player";

const Avis = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const openModal = (image) => {
    setSelectedImage(image);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div id="avis" className="bg-white pb-8">
      <div className="w-full flex justify-center items-center flex-col bg-white rounded-b-[120px] shadow-lg shadow-black/10">
        <div className="text-center bg-[var(--primary-color)] text-white px-16 py-4 rounded-b-[30px] text-xl font-bold">
          TEMOIGNAGE
        </div>
        <div className="container grid gap-4 grid-cols-1 md:grid-cols-2  lg:grid-cols-3  py-4 mx-auto items-center justify-center">
          {/* Image Slider */}
          <div className="bg-gray-200 p-4 rounded-lg w-[350px] mx-auto border">
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <CustomCarousel>
                <div className=" h-[350px]  flex items-center justify-center">
                  <ReactPlayer
                    url="https://youtu.be/E9qkjFeJE8E"
                    width="100%"
                    height={"100%"}
                    controls
                  />
                </div>
                <div className=" h-[350px]  flex items-center justify-center">
                  <ReactPlayer
                    url="https://youtube.com/shorts/zmKGbU7RYTI?feature=share"
                    width="100%"
                    height={"100%"}
                    controls
                  />
                </div>
              </CustomCarousel>
            </div>
          </div>
          {/* Image Slider */}
          <div className="bg-gray-200 p-4 rounded-lg w-[350px] mx-auto">
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <CustomCarousel>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t1.jpg"))}
                >
                  <img
                    src={require("../assets/images/t1.jpg")}
                    alt="slide"
                    className=""
                    loading="lazy"
                  />
                </div>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t4.jpg"))}
                >
                  <img
                    src={require("../assets/images/t4.jpg")}
                    alt="slide"
                    className="  "
                    loading="lazy"
                  />
                </div>
              </CustomCarousel>
            </div>
          </div>
          {/* Image Slider */}
          <div className="bg-gray-200 p-4 rounded-lg w-[350px] mx-auto">
            <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
              <CustomCarousel>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t2.jpg"))}
                >
                  <img
                    src={require("../assets/images/t2.jpg")}
                    alt="slide"
                    className=" "
                    loading="lazy"
                  />
                </div>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t3.jpg"))}
                >
                  <img
                    src={require("../assets/images/t3.jpg")}
                    alt="slide"
                    className="  "
                    loading="lazy"
                  />
                </div>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t6.jpg"))}
                >
                  <img
                    src={require("../assets/images/t6.jpg")}
                    alt="slide"
                    className=" "
                    loading="lazy"
                  />
                </div>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t7.jpg"))}
                >
                  <img
                    src={require("../assets/images/t7.jpg")}
                    alt="slide"
                    className=" "
                    loading="lazy"
                  />
                </div>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t8.jpg"))}
                >
                  <img
                    src={require("../assets/images/t8.jpg")}
                    alt="slide"
                    className=" "
                    loading="lazy"
                  />
                </div>
                <div
                  className="inline-block h-[350px]"
                  onClick={() => openModal(require("../assets/images/t9.jpg"))}
                >
                  <img
                    src={require("../assets/images/t9.jpg")}
                    alt="slide"
                    className=" "
                    loading="lazy"
                  />
                </div>
              </CustomCarousel>
            </div>
          </div>
        </div>
      </div>
      {/* Preview Modal */}
      {showModal && (
        <div
          className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-black bg-opacity-50"
          style={{ zIndex: 9999 }}
        >
          <div className="relative w-full h-full max-h-full mx-auto bg-white p-4">
            <button
              onClick={closeModal}
              className=" absolute right-2 top-2 bg-red-300 text-white px-4 py-2 mt-4 rounded-lg z-10"
            >
              Fermer
            </button>
            <img
              src={selectedImage}
              alt="preview"
              className="max-h-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Avis;
