import React from "react";
import { comments } from "../data";

const Comment = () => {
  return (
    <div className="w-full flex justify-center items-center flex-col bg-white rounded-b-[30px] shadow-lg shadow-white/50">
      <div className="text-center text-[var(--primary-color)] px-16 py-4 rounded-b-[30px] text-xl font-bold">
        COMMENTAIRES
      </div>
      <div className="container grid px-4 py-16 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 items-center justify-center ">
        {comments.map((comment) => {
          return (
            <div className="bg-white p-2 shadow shadow-black/20 rounded-lg min-h-[160px] flex flex-col justify-between" key={comment.id}>
              <h2 className="text-[18px] font-bold">{comment.name}</h2>
              <p className="text-sm">{comment.message}</p>
              <p className="text-end">
                <small className="text-muted">{comment.date}</small>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Comment;
