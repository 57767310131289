import React from "react";
import { FaCheckCircle } from "react-icons/fa";
import Buy from "./Buy";

const Desc = () => {
  return (
    <div
      id="desc"
      className="w-full flex justify-center items-center flex-col bg-primary-color rounded-b-120px shadow-lg shadow-white/30"
    >
      <div className="text-center bg-white px-16 py-4 rounded-b-30px text-xl font-bold rounded-b-3xl">
        Découvrez les secrets infaillibles pour exploser vos ventes sur
        Facebook!
      </div>
      <div className="container grid gap-4 px-4 py-16 grid-cols-1 md:grid-cols-2 items-start justify-center">
        <img
          src={require("../assets/images/3.png")}
          alt="sekou"
          className="rounded-lg w-300px md:w-400px mx-auto"
          loading="lazy"
        />
        <div className="text-white">
          <p className="text-20px mb-6">
            Êtes-vous prêt à révolutionner votre business en ligne et à
            débloquer un potentiel de ventes illimité sur Facebook ? Ne cherchez
            plus, vous êtes au bon endroit ! Notre programme exclusif vous
            dévoile les stratégies les plus puissantes pour transformer votre
            présence sur Facebook en une machine à générer des revenus.
          </p>
          <p className="text-20px mb-6">
            Imaginez-vous en train de vendre avec facilité, attirant une
            audience de qualité et convertissant vos prospects en clients
            fidèles, peu importe votre domaine d'activité. Grâce à notre
            approche unique, nous avons aidé des centaines d'entrepreneurs à
            atteindre des résultats remarquables.
          </p>
          <p className="text-20px mb-4">
            💡 Mon livre vous enseignera comment :
          </p>
          <ul className="mb-6 pl-3">
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Vendre avec votre compte Facebook sans dépenser une fortune en
              publicité.
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Créer des pages professionnelles captivantes pour attirer votre
              public cible.
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Utiliser intelligemment le boost de publication pour maximiser
              votre visibilité.
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Sponsoriser vos pages Facebook de A à Z et lancer des campagnes
              publicitaires percutantes.
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Construire une audience de qualité pour des ventes plus faciles.
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Éviter les blocages et les tracas avec les meilleures pratiques
              sur Facebook.
            </li>
            <li className="flex items-center">
              <FaCheckCircle className="mr-2 text-green-500" size={20} />
              Maîtriser l'art du copywriting pour vendre avec des mots
              percutants.
            </li>
          </ul>
          <p className="text-20px mb-6">
            📈 En achetant mon livre, vous accéderez également à un groupe
            WhatsApp exclusif, où vous pourrez échanger avec d'autres
            entrepreneurs passionnés, partager vos expériences et recevoir des
            conseils personnalisés de nos experts en vente sur Facebook. De
            plus, vous bénéficierez de séances de vidéos pratiques pour voir les
            stratégies en action.
          </p>
          <Buy />
        </div>
      </div>
    </div>
  );
};

export default Desc;
