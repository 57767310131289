import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ArrowLeft, ArrowRight } from "react-feather";

const CustomCarousel = ({ children }) => {
  return (
    <Carousel
      infiniteLoop
      swipeable={false}
      showStatus={false}
      showThumbs={false}
      renderArrowPrev={(clickHandler, hasPrev) => {
        return (
          <div
            className={`${
              hasPrev ? "absolute" : "hidden"
            } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20 `}
            onClick={clickHandler}
          >
            <ArrowLeft className="w-9 h-9 text-black" />
          </div>
        );
      }}
      renderArrowNext={(clickHandler, hasNext) => {
        return (
          <div
            className={`${
              hasNext ? "absolute" : "hidden"
            } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
            onClick={clickHandler}
          >
            <ArrowRight className="w-9 h-9 text-black" />
          </div>
        );
      }}
    >
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
