import React from "react";

const CallbackPaiementPro = () => {
  // Get transaction params status
  const params = new URLSearchParams(window.location.search);
  const status = params.get("responsecode");

  const translate = (key) => {
    // Define your translation dictionary here
    const translations = {
      "Transaction Successful": "Transaction réussie",
      "Thank you for your payment!": "Merci pour votre paiement !",
      "Your transaction was successful. We have received your payment.":
        "Votre transaction a réussi. Nous avons reçu votre paiement.",
      "Transaction Failed": "Échec de la transaction",
      "Payment unsuccessful.": "Paiement non réussi.",
      "We are sorry, but your transaction could not be processed at this time.":
        "Nous sommes désolés, mais votre transaction n'a pas pu être traitée pour le moment.",
    };

    // Return the translated text if available, otherwise return the original text
    return translations[key] || key;
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="bg-white rounded-lg p-8 h-screen w-screen flex items-center justify-center flex-col">
        {status === "0" ? (
          <>
            <div className="text-green-600 text-4xl font-bold mb-4">
              {translate("Transaction Successful")}
            </div>
            <div className="text-gray-600 text-lg mb-2">
              {translate("Thank you for your payment!")}
            </div>
            <div className="text-gray-600 text-lg">
              {translate(
                "Your transaction was successful. We have received your payment."
              )}
            </div>
          </>
        ) : (
          <>
            <div className="text-red-600 text-4xl font-bold mb-4">
              {translate("Transaction Failed")}
            </div>
            <div className="text-gray-600 text-lg mb-2">
              {translate("Payment unsuccessful.")}
            </div>
            <div className="text-gray-600 text-lg">
              {translate(
                "We are sorry, but your transaction could not be processed at this time."
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CallbackPaiementPro;
