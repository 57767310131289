import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Callback from "../screens/Callback";
import Home from "../screens/Home";
import Commande from "../screens/Commande";
import Contact from "../screens/Contact";
import CallbackPaiementPro from "../screens/CallbackPaiementPro";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/callback",
    element: <Callback />,
  },
  {
    path: "/paiement",
    element: <CallbackPaiementPro />,
  },

  {
    path: "/commande",
    element: <Commande />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default Router;
