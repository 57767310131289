import {
  addDays,
  isTomorrow,
  format,
  isToday,
  isAfter,
  setSeconds,
  setMinutes,
  setHours,
} from "date-fns";

import frLocale from "date-fns/locale/fr";
import { utcToZonedTime } from "date-fns-tz";
import { getDay } from "date-fns";

export const MESSAGE_COOLDOWN_DURATION = 60;

export const getItem = (name) => {
  return localStorage.getItem(name);
};

export const setItem = (name, value) => {
  localStorage.setItem(name, value);
};

export const isExpired = (name) => {
  const value = getItem(name);
  if (!value) {
    return true;
  }
  const currentTime = Math.floor(Date.now() / 1000);
  return currentTime - value >= MESSAGE_COOLDOWN_DURATION;
};

export const formatDate = (date) => {
  const timeZone = "Africa/Abidjan";

  const dateUtc = new Date(date);
  const dateInTimeZone = utcToZonedTime(dateUtc, timeZone);

  if (isToday(dateInTimeZone, { locale: frLocale })) {
    return "Aujourd'hui";
  } else if (isTomorrow(dateInTimeZone, { locale: frLocale })) {
    return "Demain";
  } else {
    return `Le ${format(dateInTimeZone, "d MMMM yyyy", {
      locale: frLocale,
    })}`;
  }
};

export const getMinDateForDatePicker = () => {
  const currentDate = new Date();

  const targetTime = setSeconds(setMinutes(setHours(currentDate, 9), 30), 0);

  const isAfter930AM = isAfter(currentDate, targetTime);

  return isAfter930AM ? addDays(currentDate, 1) : currentDate;
};

export const getSundaysInDateRange = (startDate, endDate) => {
  const sundays = [];
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (currentDate.getDay() === 0) {
      sundays.push(currentDate);
    }
    currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
  }

  return sundays;
};
