import React, { useState } from "react";
import { faqs } from "../data";

const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      id="faq"
      className="relative bg-transparent flex flex-col items-center justify-center"
    >
      <div className="text-center bg-[var(--primary-color)] text-white text-xl font-bold px-16 py-4 rounded-b-[30px] mb-4">
        FAQ
      </div>
      <div className="bg-container">
        <div className="absolute backdrop-brightness-[0.6] w-full h-full"></div>
        <div className="bg bg3"></div>
      </div>

      <ul className="container flex flex-col w-full bg-white p-4 rounded-2xl gap-1">
        {faqs.map((faq, index) => {
          const isActive = index === activeIndex;
          return (
            <li
              className="bg-white my-2 shadow-lg"
              key={index}
              onClick={() => toggleAccordion(index)}
            >
              <h2 className="flex flex-row justify-between items-center font-semibold p-3 cursor-pointer">
                <div className="flex justify-between items-center gap-3">
                  <svg
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                  >
                    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <path
                        d="M17 2H7C4.24 2 2 4.23 2 6.98V12.96V13.96C2 16.71 4.24 18.94 7 18.94H8.5C8.77 18.94 9.13 19.12 9.3 19.34L10.8 21.33C11.46 22.21 12.54 22.21 13.2 21.33L14.7 19.34C14.89 19.09 15.19 18.94 15.5 18.94H17C19.76 18.94 22 16.71 22 13.96V6.98C22 4.23 19.76 2 17 2ZM13 13.75H7C6.59 13.75 6.25 13.41 6.25 13C6.25 12.59 6.59 12.25 7 12.25H13C13.41 12.25 13.75 12.59 13.75 13C13.75 13.41 13.41 13.75 13 13.75ZM17 8.75H7C6.59 8.75 6.25 8.41 6.25 8C6.25 7.59 6.59 7.25 7 7.25H17C17.41 7.25 17.75 7.59 17.75 8C17.75 8.41 17.41 8.75 17 8.75Z"
                        fill="#292D32"
                      ></path>{" "}
                    </g>
                  </svg>

                  <span className="text-sm md:text-md lg:text-lg">
                    {faq.question}
                  </span>
                </div>

                <svg
                  viewBox="0 -4.5 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                  className={`fill-current text-[var(--primary-color)] h-6 w-6 transform transition-transform duration-500 ${
                    isActive ? "rotate-180" : ""
                  }`}
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_down [#339]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-180.000000, -6684.000000)"
                        fill="#000000"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M144,6525.39 L142.594,6524 L133.987,6532.261 L133.069,6531.38 L133.074,6531.385 L125.427,6524.045 L124,6525.414 C126.113,6527.443 132.014,6533.107 133.987,6535 C135.453,6533.594 134.024,6534.965 144,6525.39"
                            id="arrow_down-[#339]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </h2>
              <div
                className={`border-b-2 border-black overflow-hidden max-h-0 duration-500 transition-all ${
                  isActive ? "max-h-[300px]" : ""
                }`}
              >
                <p className="p-3 text-gray-900">{faq.answer}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Faq;
