import React from "react";
import Buy from "./Buy";

const Hero = () => {
  return (
    <section className="mt-[60px] bg-transparent relative h-[calc(100vh-120px)] flex flex-col items-center justify-center">
      <div className="bg-container">
        <div className="absolute backdrop-brightness-[0.6] w-full h-full"></div>
        <div className="bg bg1"></div>
        <div className="bg bg2"></div>
      </div>

      <div className="container mx-auto flex flex-col items-center px-4 py-16 text-center md:py-32 md:px-10 lg:px-32 xl:max-w-4xl">
        <h1 className="text-2xl font-bold leading-none sm:text-3xl text-[var(--primary-color)] bg-white p-5 rounded-md">
          Le livre magique qui te permet d’être un géant sur FACEBOOK
        </h1>
      </div>

      <div className="flex flex-wrap justify-center absolute bottom-6">
        <Buy />
      </div>
    </section>
  );
};

export default Hero;
