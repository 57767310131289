import React from "react";
import Buy from "./Buy";

const Footer = () => {
  return (
    <div className="bg-[var(--primary-color)] p-3 mt-3">
      <div className="flex flex-col items-center justify-between w-full p-2 md:flex-row ">
        <ul className="grid items-center justify-center grid-cols-2 list-none sm:grid-cols-3 md:grid-cols-4 ">
          <a href="/">
            {" "}
            <li className="m-3 text-center text-white">Accueil</li>
          </a>
          <a href="#avis">
            <li className="m-3 text-center text-white">Témoinagnage</li>
          </a>
          <a href="#faq">
            <li className="m-3 text-center text-white">FAQ</li>
          </a>
          <a href="#desc">
            {" "}
            <li className="m-3 text-center text-white">A propos</li>
          </a>
        </ul>
        <Buy />
      </div>

      <p className="text-center text-white">
        {new Date().getFullYear()} - Tous droits reservés à{" "}
        <a href="https://services.sc-digital.org">
          <span className="text-[#fff] font-bold">SC Digital</span>
        </a>
      </p>
    </div>
  );
};

export default Footer;
