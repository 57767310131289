import React from "react";

const External = () => {
  return (
    <div id="autre" className="pb-8 bg-white">
      <div className="w-full flex justify-center items-center flex-col bg-white rounded-b-[120px] shadow-lg shadow-black/10">
        <div className="text-center bg-[var(--primary-color)] text-white px-16 py-4 rounded-b-[30px] text-xl font-bold">
          Autres livres
        </div>
        <div className="flex flex-wrap items-center justify-center gap-4 mt-8">
          <div className="w-64 overflow-hidden rounded-lg h-80">
            <a href="https://whatsapp.sc-digital.org">
              <img
                src={require("../assets/images/whatsapp.png")}
                alt="whatsapp"
                className="object-cover h-full"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default External;
